body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
}

body.loaded {
    overflow-y: auto;
}

main {
    font-size: .9em;
}

img {
    max-width: 100%;
}

div,
img,
video,
span,
body {
    box-sizing: border-box;
}

.menu {
    z-index: 99999;
    width: 100%;
    position: fixed;
    transition: transform ease-in-out 1000ms;
    transform: translateY(calc(100vh - 40px));
}

.menu button.active {
    color: #FF5722;
}
.menu button.active:hover {
    color: #FF5722;
}

.menu .image-grid {
    padding-top: 0;
}

.menu .g-50 {
    width: 100%;
}

#full-screen {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .9);
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    transition: opacity ease-in-out 1000ms;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#full-screen.open {
    opacity: 1
}

.floating-menu {
    background-color: black;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hidding-menu {
    z-index: 99998;
    background-color: white;
    height: calc(100vh - 40px);
    width: 100vw;
}

.open.menu {
    transform: translateY(0);
}

.open .hidding-menu {
    overflow-y: scroll;
}

.invert-icon {
    display: inline-block;
    filter: invert(1);
    vertical-align: middle;
    height: 26px;
    flex: 1;
}

#loading-section {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 1;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    transition: opacity ease-in-out 2500ms;
}

#loading-section.ready {
    opacity: 0;
}

#loading-section img {
    width: 60px;
    margin-bottom: 8px;
}

#fade {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99999;
    background-color: white;
    transition: opacity ease-in-out 500ms;
    opacity: 0;
    top: 0;
    left: 0;
    pointer-events: none;
}

#fade.show {
    opacity: 1;
}

.biblio p {
    font-size: .9em;
    color: white;
}

.biblio {
    margin-bottom: 0;
}

.jumpToMenu {
    height: 50px;
}
.biblio button.jumpToButton {
    display: inline-block;
}
button.jumpToButton:hover {
    color: #d6b16d;
}

.biblio button.jumpToButton .text {
    color: wheat;
    text-decoration: underline;
}
.biblio button.jumpToButton .arrow {
    font-size: 3em;
    padding-left: .4em;
    color: white;
}

.video-container {
    width: 100%;
    max-height: 90vh;
    position: relative;
}

.overlay > div {
    width: 100%;
    position: absolute;
    bottom: 74px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    z-index: 1;
    flex-direction: column;
}

.overlay img {
    height: calc(100% - 74px);
    object-fit: contain;
    font-family: 'object-fit: contain;';
    pointer-events: none;
}

video {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-height: 90vh;
    outline: none;
}

.hm,
.hm.image-container,
.hm.image-grid {
    display: none;
}

.author {
    text-align: right;
    padding-top: 1em;
}

.author strong {
    padding-top: 1em;
    font-weight: 500;
}

.author strong span.strong {
    font-weight: bold;
}

.credit-title {
    margin-bottom: 1em;
    display: block;
}

button.jumpToButton {
    background: none;
    outline: none;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 0;
    padding: .6em 0;
    font-weight: bold;
    text-align: left;
}

p > q {
    text-align: right;
}

p.right > q {
    display: inline-block;
    width: 80%;
}

p.right > q {
    width: 90%;
}

.mini-author {
    font-weight: 300;
    font-size: .8em;
}

.header-section {
    /* background-image: url(../res/images/low_josefina.jpg); */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: top;
}

 .header-section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    position: relative;
 }

 #first-layer,
 #second-layer {
    background-position: top;
    background-size: cover;
    position: absolute;
    height: calc(100vh + 80px);
    width: 100%;
    content: ' ';
    top: -80px;
    left: 0;
 }

 #first-layer {
    z-index: -1;
    background-image: url(../res/images/slider_josefina_1.jpg);
 }

 #first-layer.transition {
    transition: opacity ease-in-out 5000ms;
    opacity: 0;
 }

 #second-layer {
    z-index: -2;
     background-image: url(../res/images/slider_josefina_2.jpg);
 }

 .header-section h1 {
    font-weight: 300;
    color: white;
    letter-spacing: 5px;
    z-index: 2;
    font-family: 'Lora', serif;
    width: 70%;
    padding-right: .3em;
    font-size: 10vw;
    text-align: center;
    padding-bottom: 3em;
}

 .gooey-effect-shape {
    font-size: .7em;
    color: rgba(195, 195, 195, .3) !important;
 }
 
/* .overlay-portada {
    background: radial-gradient(ellipse at center, rgba(169,3,41,1) 0%, rgba(143,2,34,1) 49%, rgba(109,0,25,1) 75%, rgba(109,0,25,1) 100%);
 }

 #overlay-portada {
    background: radial-gradient(ellipse at center, rgb(0 0 0 / 28%) 0%, rgb(10 10 10 / 29%) 26%, rgb(3 3 3 / 25%) 27%, rgb(6 0 2 / 77%) 75%, rgba(0,0,0,1) 100%);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
 }
 */

 .logos {
    transition: transform 200ms;
    display: flex;
    padding: 1px 0;
    width: 100%;
    background-color: white;
    justify-content: center;
    z-index: 999;
 }
 .logos img {
    width: auto;
    object-fit: contain;
    font-family: 'object-fit: contain;';
 }

.initial-animation {
    transform: translateY(calc(-100% - 40px));
 }

.intro {
    border-top: 10px solid black;
}

.section {
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
}

.section.audio {
    min-height: auto;
}

.section.border::after {
    content: '';
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    display: block;
    height: 1px;
    border-bottom: 14px solid black;
}

div.section:first {
    padding-top: 4em;
}

.section h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 3em;
    margin: 0.67em 0;
}

.section.artist h1 {
    margin-bottom: .67em;
}

.section.artist h3 {
    font-weight: 800;
    padding-top: 0;
    margin-top: 0;
    font-size: 1.4em;
}

.section h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 2.2em;
    margin: 0 0 1.4em;
}

p {
    font-family: 'Lora', serif;
    line-height: 2em;
    font-weight: 500;
}

hr {
    border: 0;
    padding-top: 1.4em;
    width: 100%;
}

.quote-block q {
    position: relative;
    display: block;
}

q, 
q > p {
    font-style: italic;
}

.quote-block q > p {
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 400;
    font-size: .9em;
    font-family: 'Poppins';
    padding: 0 .3em;
}

.quote-block {
    position: relative;
    margin: 3em 0 4em 0;
}

/* .quote-block::before,
.quote-block::after {
    content: '';
    border-bottom: wheat 5px solid;
    position: absolute;
    width: 40%;
    margin-left: 30%;
} */

.quote-block q {
    padding-top: 1em;
    padding-bottom: 1.8em;
}
.section {
    padding-top: 1.8em;
    padding-bottom: 1.8em;
}

.container,
.cartela-container {
    padding: 1em;
    max-width: auto;
    width: 100%;
    margin: 0 auto;
}

.container-menu {
    padding: 1em;
    width: 100%;
}

.ff {
    margin-bottom: 3em;
    margin-top: 2em;
    flex-direction: row;
    display: flex;
    align-items: stretch;
}

.ff h1 {
    font-size: 3em;
}

.ff > div {
    padding: 1em 1em 2.4em;
    background: #fff0d1;
    width: 100%;
}

.ff::before {
    content: ' ';
    width: 100%;
    max-width: calc(50% - 408px);
}

.ff::after {
    content: ' ';
    width: 100%;
    background: #fff0d1;
    max-width: calc(50% - 408px);
}

.ff p {
    white-space: normal;
}

.cartela {
    font-size: .8em;
    background-color: black;
    text-align: left !important;
}

.cartela.image-grid {
    padding: 0;
}

.cartela-container {
    color: white;
    margin-bottom: 1em;
}
.cartela-container h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.6em;
    margin: .2em 0;
}
.cartela-container h3 {
    margin-bottom: 1em;
    margin-top: 0;
}
.cartela-container p {
    font-size: .9em;
    line-height: 1.4em;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
/*
.header-section h1 {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, .2);
    font-size: 9vw;
    font-weight: 600;
    margin: 0;
    padding-bottom: 1.4em;
    z-index: 2;
    pointer-events: none;
    color: wheat;
}
*/
.image-container {
    line-height: 0;
    display: inline-block;
}

.image-label {
    display: flex;
    line-height: 1.3em;
    background-color: black;
    color: white;
    padding: .8em;
    font-weight: 100;
}
.image-label > div {
    flex-grow: 1;
    width: 0;
}

.q .image-label {
    font-style: none;
}

.image-fixed {
    width: 100%;
    height: 50vh;
    background-attachment: fixed;
}

img.image-dinamic {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    object-position: center 0;
    font-family: 'object-position: center 0;';
    background-color: white;
    transition: object-position 1500ms ease;
}

.full-width-image {
    width: 100%;
    height: 75vh;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    display: block;
}

.block {
    display: block;
}

.lm-150,
.rm-150,
.rm-200 {
    display: block;
    margin: 0 auto;
    padding: 0 0 1em 0;
    width: fit-content;

}

.rm-150 img,
.lm-150 img,
.rm-200 img {
    max-height: 80vh;
    margin-top: .6em;
    max-width: 100%;
}

.no-padding,
div.no-padding,
q.no-padding,
h2.no-padding {
    padding: 0;
}

.no-margin,
h2.no-margin,
h1.no-margin,
div.no-margin {
    margin: 0;
}

.white-no-wrap {
    white-space: nowrap;
}

.logos img.desktop {
    display: none;
}


.quote-block q::before,
.quote-block q::after {
    display: none;
}

/* Birghtness effect */

@keyframes brightness {
    0%, 100% { filter: brightness(1) }
    10%, 90% { filter: brightness(.95) }
    50% { filter: brightness(.85) }
}

.brightness {
    animation: brightness 4s infinite;
    transition: filter 2000ms ease-in;
}

.brightness:hover {
    animation: step-start;
}

@keyframes invert {
    0% { filter: contrast(1); }
    50% { filter: contrast(1.8); }
    100% { filter: contrast(1); }
}

.invert {
    animation: invert 4s infinite;
    transition: filter 4s;
}

@media screen and (min-width: 650px) {
    .lm-150,
    .rm-150,
    .rm-200 {
        text-align: left;
        display: block;
    }
    .lm-150 {
        float: left;
        padding: 0 2em 0 0;
    }

    .rm-150,
    .rm-200 {
        float: right;
        padding: 0 0 0 2em;
    }
}

@media screen and (min-width: 768px) {
    .menu .g-50 {
        width: 50%;
    }
    .menu .g-50:first-of-type {
        padding-right: 20px;
    }
    .quote-block q > p {
        font-size: 1em;
        padding: 0 3em;
    }
    .a-50 {
        max-height: 50vh !important;
    }   
    .quote-block q::before,
    .quote-block q::after {
        display: inline-block;
        position: absolute;
        color: #c7c7c7;
        font-weight: 400;
        margin-left: -1.8rem;
        font-style: italic;
        font-size: 12rem;
        vertical-align: bottom;
        padding-right: 2rem;
    }
    .quote-block q::before {
        margin-left: -4rem;
        padding-right: 2rem;
    }
    .quote-block q::after {
        margin-right: -1.2rem;
        bottom: -6.4rem;
        right: 0;
    }
    .section {
        padding-top: 4em;
        padding-bottom: 4em;
    }
    main {
        font-size: 1em;
    }
    .gooey-effect-shape {
        font-size: .7em;
    }
    .header-section h1 {
        width: 50%;
        padding-right: 1em;
        font-size: 4vw;
     }
    .header-section,
    .header-section::before  {
        background-position: bottom;
     }
    .section h1 {
        font-size: 3.5em;
    }
    .ff > div {
        padding: 1em 4em 2.4em;
    }
    .container, .cartela-container {
        padding: 2em;
        max-width: calc(800px + 2em);
    }
    .container-menu {
        padding: 4em;
    }
    .logos img.desktop {
        display: block;
    }
    .logos img.mobile {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .quote-block q::before,
    .quote-block q::after {
        margin-left: -3.4rem;
        padding-right: 2rem;
    }
    
    .quote-block q::before {
        margin-left: -6.4rem;
    }
    
    .quote-block q::after {
        margin-right: -3.4rem;
    }
    .hm,
    .hm.image-container,
    .hm.image-grid {
        display: block;
    }
    .image-container.hm {
        display: inline-block;
    }
    .image-grid.hm {
        display: flex;
    }
    .sm {
        display: none !important;
    }
}
.virgen {
    padding-top: 1em !important;
}
@media screen and (min-width: 1110px) {
    .virgen {
        padding-top: 0 !important;
    }
}
@media screen and (min-width: 1050px) {
    .section h1 {
        font-size: 3.8em;
    }
    .ff > div {
        white-space: nowrap;
    }
    .lm-150 {
        margin-left: -100px;
    }
    .rm-200 {
        margin-right: -200px;
    }
    .rm-150 {
        margin-right: -100px;
    }
}

@media screen and (min-width: 1250px ) {
    .lm-150 {
        margin-left: -200px;
    }
    .rm-200 {
        margin-right: -300px;
    }
    .rm-150 {
        margin-right: -200px;
    }
}


/**
    Clearfix
 */
 .cf:before,
 .cf:after {
     content: " "; /* 1 */
     display: table; /* 2 */
 }
 
 .cf:after {
     clear: both;
 }
 
 .cf {
     *zoom: 1;
 }


img {
	opacity: 1;
	transition: opacity 0.3s;
}

img[data-src] {
	opacity: 0;
}

q.no-quotes::before,
q.no-quotes::after {
    content: '';
}
/* Goebius animation */
.gooey-effect-shape {
    text-align: center;
    line-height: 16vh;
    color: #ccc;
    width: 16vh;
    height: 16vh;
    white-space: nowrap;
    /* background-color: #f5f5f5;
    background-color: rgba(0, 0, 0, .25);*/
    position: absolute;
    transition: left 10s, top 10s, background-color 300ms;
    border-radius: 50%;
}
/*.gooey-effect-shape:hover {
    background-color: rgba(0, 0, 0, .55);
}*/
/* Crazy animation */
/*
#gooey-effect-container {
    filter: blur(15px) contrast(30);
    top: -15%;
    left: -30%;
    width: 200%;
    height: 200%;
    position: absolute;
    background: #ae1ee9;
    z-index: -1;
}
.gooey-effect-shape {
    .width: 100vh;
    height: 50vh;
    background-color: #f5f5f5;
    background-color: black;
    position: absolute;
    transition: 2s;
    border-radius: 50%;
}
/* Grid-image */
.grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.image-grid {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding-top: 3em;
    padding-bottom: 3em;
}

.center-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.with-padding {
    padding: 0 1em;
}

.with-margin {
    margin: 1em;
}

.with-margin-vertical {
    margin-top: 1em;
    margin-bottom: 1em;
}
.with-margin-vertical-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.image-grid.with-padding,
.image-grid.with-padding img {
    padding: 6px;
}

.image-grid.with-padding .flex-adjust img {
    padding-bottom: 0;
}

.image-grid.with-padding .image-label {
    margin: 0;
    margin-top: 0;
}

.image-grid.with-padding .image-label.no-margin {
    margin-left: 0;
    margin-right: 0;
}

.image-grid.with-padding {
    padding-bottom: 3em;
}

.image-grid.with-padding.no-padding {
    padding: 0;
}

.image-grid.no-bottom-padding {
    padding-bottom: 0;
}

.image-grid.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
}

div.image-grid .no-padding-left {
    padding-left: 0;
}

div.image-grid .no-padding-right {
    padding-right: 0;
}

.flex-adjust,
.flex-adjust--no-label {
    display: flex;
    flex-direction: column;
}
.flex-adjust .image-label,
.flex-adjust--no-label img {
    flex: 1;
}

.flex-adjust--no-label img {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.with-padding-left {
    padding-left: 6px;
}

.with-padding-right {
    padding-right: 6px;
}

.with-margin-left {
    margin-left: 6px;
}

.with-margin-right {
    margin-right: 6px;
}

.image-grid img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.g-16 {
    width: 16.66%;
}
.g-20 {
    width: 20%;
}
.g-25 {
    width: 25%;
}
.g-30 {
    width: 30%;
}
.g-33 {
    width: 33.33%;
}
.g-40 {
    width: 40%;
}
.g-45 {
    width: 44%;
    margin-left: 1%;
    object-position: right;
}
.g-50 {
    width: 50%;
}
.g-55 {
    width: 55%;
}
.g-60 {
    width: 60%;
}
.g-66 {
    width: 66.66%;
}
.g-70 {
    width: 70%;
}
.g-75 {
    width: 75%;
}
.g-80 {
    width: 80%;
}
.g-100 {
    width: 100%;
}
.h-50 img {
    height: 50vh;
}
.h-60 img {
    height: 60vh;
}
.h-70 img {
    height: 70vh;
}
.h-80 img {
    height: 80vh;
}
.h-90 img {
    height: 90vh;
}
.h-100 img {
    height: 100vh;
}
.nowrap {
    flex-flow: nowrap;
}
.center {
    display: block;
    margin: 0 auto;
    text-align: center;
}
.right {
    text-align: right;
    align-self: right;
}
.p-3 {
    padding-top: 3em;
}
.firma-box {
    display: inline-block;
    width: 90%;
}
.firma-box.g-70 {
    width: 70%;
}
.special-width {
    flex: 1;
    width: 1px;
}
.audio-container {
    padding: 2em 6px;
    position: relative;
}

.gradient {
    background: linear-gradient(to bottom, rgb(232 232 232) 0%, rgb(255 150 150) 100%);
}

.audio-container .audio {
    position: sticky;
    top: 2em;
}
audio {
    outline: 0 !important;
    width: 100%;
}
@media screen and (min-width: 992px) {
    .image-grid.responsive .g-33,
    .image-grid.responsive .g-50 {
        width: 20%;
    }
    .image-grid.responsive .g-66 {
        width: 40%;
    }
}

body {
    overflow-x: hidden;
}
#zoom-container {
    overflow: hidden;
    height: 100vh;
    background-color: #ccc;
    position: relative;
    width: 100%;
}
.pdf-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
}
.df-ui-more {
    display: none;
}
.region-content .page-header {
    display: none;
}
.df-floating .df-ui-controls {
    position: absolute;
    bottom: 30px;
}
#floating-recommendation {
    z-index: 1;
    position: absolute;
    width: 100vw;
    top: 0;
    display: none;
    text-align: center;
    padding: .8em;
    background: #f7f7f7;
    box-shadow: 0 1px 4px rgba(0,0,0,0.3);
    font-size: .7em;
    text-transform: uppercase;
    color: #666;
}

.active #floating-recommendation {
    display: block;
}

.full-view {
    position: absolute;
    top: 2em;
    right: 1em;
    z-index: 2;
    transform: scaleX(2) scaleY(2);
    opacity: .4;
    cursor: pointer;
    display: none;
}

.full-view.visible {
    display: block;
}

.full-view:hover {
    opacity: .7;
}

.full-view.fullscreen {
    right: 1.1em;
    transform: scaleX(1.3) scaleY(1.3);
}

.active .full-view {
    top: 3.5em;
}

.active .full-view.fullscreen {
    top: 3.5em;
}

.mobile .ti-zoom-in,
.mobile .ti-zoom-out {
    display: none;
}

.df-ui-btn {
    padding: 12px 26px;
}

.ti-fullscreen:before {
    right: 0 !important;
}
[class^="ti-"]:before, [class*=" ti-"]:before {
    position: absolute;
    right: 12px;
}

.floating-buttons-container {
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 30;
}

.btn-floating {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    padding: .6em;
    margin-top: .6em;
    border: 0;
    bottom: 3em;
    display: block;
    cursor: pointer;
}

.btn-floating img {
    width: 100%;
    height: 100%;
}

.full-view.visible {
    right: 4em;
}
.df-share-box [class*=" ti-"]:before {
    position: relative;
}
@media (max-width: 768px) {
    .full-view {
        top: 1em;
    }
    .full-view.fullscreen {
        top: .6em;
    }
    .full-view.visible {
        right: 1em;
    }
    .floating-buttons-container {
        bottom: 3em;
    }
    .df-ui-wrapper.df-ui-controls .df-ui-btn {
        display: inline-block;
    }
    .df-floating .df-ui-controls {
        bottom: 0;
        width: 100%;
        display: block;
    }
    .ti-angle-double-right,
    .ti-angle-double-left,
    .ti-layout-grid2 {
        display: none !important;
    }
    .df-floating .df-ui-controls>.df-ui-btn:first-child {
        border-radius: 0;
    }
    .df-floating .df-ui-controls>.df-ui-btn:last-child {
        border-radius: 0;
    }
}

.df-floating .df-ui-controls.fullscreen {
    display: none;
}
div.dialog-off-canvas-main-canvas {
    padding-top: 0;
}
.poema {
    text-align: center;
    margin: 3em 0;
    display: block;
    line-height: 2.5em;
    font-style: italic;
}
.halley {
    object-position: bottom;
    font-family: 'object-position: bottom;';
}
.estatua-josefina {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.estatua-josefina img {
    height: 80vh;
}
.estatua-josefina .img1 {
    width: 60%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    object-position: top;
    font-family: 'object-position: top;';
}
.estatua-josefina .img2 {
    width: 40%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    object-position: top;
    font-family: 'object-position: top;';
}

.rose {
    background-color: mistyrose;
}

.rose img {
    height: 50vh;
}

.gray,
.artist.gray {
    background-color: #f1f1f1;
}

.black,
.artist.black {
    background-color: black;
    color: white;
}

.black p {
    font-weight: 200;
}

.white,
.artist.white {
    background-color: white;
}

.white.cartela-container {
    color: black;
}

.adriana_bustos {
    margin: 0 auto;
    height: 100vh;
    display: block;
}

.justify {
    justify-content: center;
}
.contain img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
}
.contain-self {
    object-fit: contain !important;
    font-family: 'object-fit: contain;' !important;
}
.cover img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.no-width img {
    width: auto;
}

.bustos .cartela {
    background-color: black
}

.reinoso .special img{
    height: 250vh;
}
.reinoso .special-effect img {
    position: sticky;
    height: 90vh;
    top: 0;
    padding-bottom: 12px;
}

.section.watson {
    background-color: #e1dbd8;
}
.para-todos img {
    height: 140px;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
}
.splide__pagination {
    display: none !important;
}
.ptop img,
.ptop {
    object-position: top;
    font-family: 'object-position: top;';
}
.pright,
.pright img {
    object-position: right;
    font-family: 'object-position: right;';
}
.pcenter img,
.pcenter {
    object-position: center;
    font-family: 'object-position: center;';
}
.pbottom img,
.pbottom {
    object-position: bottom;
    font-family: 'object-position: bottom;';
}
.churba video {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    padding: 6px;
    height: 100%;
    max-height: none;
}

.button-spivak {
    text-decoration: none;
    border: 3px solid #b4700d;
    width: 300px;
    padding: 1.2em 0;
    text-transform: uppercase;
    border-radius: 4px;
    margin-top: 1.5em;
    color: black;
    font-weight: bold;
    font-family: 'Lora', serif;
    cursor: pointer;
}

.button-spivak:hover {
    background-color: #edd1a9;
}

.splide-revista {
    max-width: 576px; 
    width: 100%;
}
.farina {
    background-color: #fff0d1;
}

.teamo h1 {
    font-size: 3.7em;
}

.dossier {
    padding: 2em;
    font-size: 2.4vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dossier-ii {
    margin-top: 4em;
}

.nistor img.lf {
    object-fit: contain;
    font-family: 'object-fit: contain;';
    max-height: 100vh;
    object-position: right;
    font-family: 'object-position: right;';
    padding-right: 5em;
}

.nistor img.rf {
    object-fit: contain;
    font-family: 'object-fit: contain;';
    max-height: 100vh;
    object-position: left;
    font-family: 'object-position: left;';
    padding-left: 5em;
}

.galay img {
    max-height: 80vh;
}

.de-caro .author {
    font-size: .9em;
}

.lsar {
    margin-right: 200px;
}

.rsar {
    margin-left: 200px;
}

.special-reyes .image-grid.with-padding img {
    padding: 0;
    margin-top: 1em;
}

.julian-galay .image-grid.with-padding, .julian-galay .image-grid.with-padding img {
    padding-bottom: 6px;
}

@media screen and (min-width: 768px) {
    .sobrinos .image-grid {
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .torcuato {
        width: 60% !important;
    }
    .julian-galay img {
        width: 100%;
    }
    .para-todos img {
        height: 80px;
    }
    .carlos-herrera img,
    .verano img,
    .adriana-bustos img,
    .trepat img {
        width: 100% !important;
    }
    .gladys-nistor .g-16 {
        display: none;
    }
    .gladys-nistor img {
        width: 66.66%;
        padding: 0 16px !important;
    }
    .julian-galay .g-70 {
        width: 100% !important;
    }
    .julian-galay .g-30 {
        width: 100% !important;
    }
    .julian-galay .g-30 audio {
        position: relative !important;
    }
    .julian-galay .with-padding {
        padding-bottom: 6px;
    }
    .roman-vitali img,
    .liliana-porter img,
    .pauline-fondevila img,
    .manu img,
    .orensanz img {
        width: 100% !important;
        height: auto !important;
    }
    .spivak .contain img {
        object-fit: contain;
        font-family: 'object-fit: contain;';
        height: auto;
    }
    main {
        overflow-x: hidden;
    }
    #first-layer, 
    #second-layer {
        background-position: center left;
    }
    .header-section h1 {
        text-align: left;
        padding-left: 1em;
        width: 100%;
    }
    .mobile-edit .g-50,
    .mobile-edit .g-60 {
        width: 80% !important;
        margin: 0 auto;
    }
    .lsar,
    .rsar {
        margin-right: 0;
        margin-left: 0;
    }
    .mobile-edit .with-margin-vertical {
        margin: 0;
    }
    .progreso-club .splide-normal {
        width: 100%;
    }
    .dossier {
        font-size: 3.6vw;
    }
    .julian-galay .image-grid.with-padding img {
        padding: 0;
        margin: 6px 0;
        object-fit: none;
    }
    .julian-galay {
        flex-flow: column-reverse;
    }
    .gruta.mobile-edit .g-50 {
        width: 100% !important;
        margin: 0 auto;
    }
    .josefina .g-60 {
        width: 100%;
    }
}

.pj-m {
    padding-bottom: 8px;
    font-weight: bold;
}

.ancalu .rm-150 img {
    max-height: none;
}

.audio .cartela {
    font-size: 1em;
}
#webGPL {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

#loading-3d-model {
    position: absolute;
    height: 40px;
    top: calc(50vh - 50px);
    left: calc(50vw - 20px);
}